// @flow
import React from 'react';
import { graphql } from 'gatsby';
import {
  AllImagesLink,
  CanyonRatingCard,
  GlossaryLink as G,
  Image,
  Layout,
  Link,
  withFrontMatter,
  YoutubeVideo,
} from '../../components';
import type { FrontMatter, ImageType } from '../../utils/types';
import route from '../../routes';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
  },
};

class Blog_2018_03_03_Sandthrax extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="4AXII" title="Sandthrax" />
        <p>
          Starting out scared and timid, I finished my first{' '}
          <G id="x-risk-rating">X-rated</G> canyon happy and confidently.
        </p>
        <p>
          This won't be a traditional trip report. Descending Sandthrax made me
          realize that I had gotten complacent and wasn't respecting canyons
          like I should.
        </p>
        <h2>Postmortem for Sandthrax</h2>
        <p>
          On a scale from <b>A</b> (perfect descent) to <b>F</b> (injury
          resulting in rescue)
        </p>
        <p>
          If I were to grade myself on my personal performance, I would give
          myself a <b>C-</b>.
        </p>
        <h3>The Good</h3>
        <ul>
          <li>We survived</li>
          <li>We got out before dark</li>
          <li>No injuries</li>
        </ul>
        <h3>The Bad</h3>
        <ul>
          <li>I was not prepared - only had cursory knowledge of the canyon</li>
          <li>I was slow</li>
          <li>I was scared</li>
          <li>I could not have finished the canyon without Anthony</li>
        </ul>
        <p>
          All the good is condensed into this video. I'm glad I can go back and
          see how much fun it was to be in there. I'm going to focus on the bad.
        </p>
        <YoutubeVideo
          title="sandthrax_long"
          src="https://www.youtube.com/embed/upNCyeUCaqE"
        />
        <h3>I was not prepared</h3>
        <p>
          Beta - I honestly didn't think I would be doing Sandthrax that day.
          Even a few hours before I wasn't sure it was going to happen.
          Consequently I hadn't read any beta and trusted completely in Anthony,
          who had done it before. That's my excuse.
        </p>
        <p>
          Experience - In terms of <G>high-stemming</G>, I believe I had
          experienced enough high stemming in canyons that I knew more or less
          what to expect. The stemming was quite similar to Upper Stair though,
          of course, it was higher and considerably longer. East Scorpion has a
          long high-stemming section for an R canyon and so I was comfortable
          with the idea of stemming for several hours without any break. I had
          intended to continue working my way up to X canyons with some others,
          but the opportunity to do these canyons never came and Sandthrax did,
          so I took it.
        </p>
        <p>
          Crux - I also was completely unprepared for the crux. I had to trust
          Anthony completely for this one. I was aware of the standard "Number 5
          and Number 6 cams" but have never even seen those cams in real life
          let alone have them with me or used them.
        </p>
        <h3>I was slow</h3>
        <p>
          I need to be honest here. I am out of shape. Doing an occasional hike
          in the Wasatch mountains doesn't adequately prepare someone for
          high-stemming. It requires sustained upper body strength. During one
          of my many breaks where Anthony was up ahead waiting for me to catch
          up, I told him that I had thought I was in shape for the canyon. That
          was a lie. In the month prior, I had only exercised my upper body once
          for about half an hour. We would have finished the canyon a good hour
          earlier if I had been exercising regularly.
        </p>
        <p>
          I mentioned that I felt more secure <G>chimneying</G> than{' '}
          <G>galumphing</G> because my legs felt more secure. That was a lie. I
          was just tired. I also said I was worried about my shoes not getting
          very good traction. That is partially true. I was tired and making
          excuses to myself. However, those shoes have hundreds of miles on them
          and the sole is definitely worn down.
        </p>
        <h3>I was scared</h3>
        <p>
          For the first half of the canyon, I definitely felt like I didn't
          belong in this canyon. I was timid, unsure of my abilities, and very
          nervous. I remember thinking on multiple occasions that I regretted
          being there and that if there were any escape options I would take
          them. I also remember thinking that I probably burned 1000 calories
          being nervous :)
        </p>
        <p>
          Near the beginning of the canyon we have to climb an arete. You
          transition from two-wall-stemming to one-wall-climbing. If I had been
          able to control my nerves and actually paid attention to what I was
          doing I could have picked a good line to go up. I didn't. When I
          transitioned to the one wall I panicked. Anthony had to come down,
          anchor himself, and provide a hand to pull me up.
        </p>
        <p>
          Anthony called out the first <G>silo</G> crossing and my heart just
          sank. I watched him switch from stemming to <G>bridging</G>, cross the
          silo, and became terrified. At that point I was already pretty tired
          (did I mention we came out of{' '}
          <Link to={route.blog_2018_03_03_no_kidding}>No Kidding</Link> just a
          few hours earlier?) and didn't even know if I had the willpower to go
          any farther. All nerves of course. He asked if I was okay and I
          pointed to my head and said it's all up here. Crossing it was of
          course much easier than I had worked it up to be. And Anthony had the
          good sense to get me talking so I could relax a little and start
          focusing on the beauty of what we were doing rather than the danger
          and difficulty.
        </p>
        <h3>I could not have finished the canyon without Anthony</h3>
        <p>
          Knowing he had descended the canyon before and seeing first-hand that
          he was a better climber than I was, I basically just blindly trusted
          he would be able to complete the canyon without any difficulty. I
          should have known better.
        </p>
        <p>
          I have also mentioned a couple of things that he did that I consider
          essential - provided a hand or a calming word when I needed it. He
          also provided a belay on one of the silos and climbing the crux. When
          I was tired and going slow, he took the rope I was carrying for much
          of the rest of the canyon. This was in addition to the Big Bro and{' '}
          <G>etriers</G> he was already carrying.
        </p>
        <h2>Improvements</h2>
        <p>
          I want to do more X canyons. But I'm not going to do them unless I
          perform significantly better than I did in Sandthrax. Again I will
          list the bad but this time I will say what I want to do to be better.
        </p>
        <h3>I was not prepared</h3>
        <p>
          Beta - I need to take the time to read beta again. When I first
          started I would read every piece of information I could about a canyon
          before descending it. I would have GPS waypoints or would make my own
          tracks. I would create my own maps and highlight potential landmarks
          to watch out for. I would make so many notes and read so much beta
          that by the time we were in the canyon I would no longer need it and
          could just tell people what to expect next. I need to get back to
          that.
        </p>
        <p>
          Experience - I intend to go back and descend all the canyons that I
          wanted to do to prepare me for Sandthrax. It's really just Egypt 5 and
          Raven. I want to then start working my up the X canyon list until I
          feel comfortable leading people through Sandthrax.
        </p>
        <p>
          Crux - First thing is to get a couple of big cams and find a place to
          practice climbing with them. I know plenty of trad climbers so I'll
          just tag along for a while until I feel more comfortable placing and
          relying on pro.
        </p>
        <h3>I was slow</h3>
        <p>
          This one is easy. Start weight lifting again. A day after the canyon,
          I went to the gym and did every lift I know. I wanted to know what
          muscles were most sore and give them particular focus. The most
          painful exercise was dips, which seems pretty obvious now that I think
          about it. The other muscle that was most sore was actually the
          posterior deltoid. I think it was sore just from using my arms so
          much. I dunno. Oh and of course I used my core a lot galumphing.
          Anyway, I'm going to give more attention to my upper body so I can
          move more quickly without getting tired.
        </p>
        <p>
          Along with weight-lifting, Anthony mentioned that he was doing yoga in
          preparation for the Pit of Despair throw. I am very not-flexible but
          hadn't every really considered a problem until I saw the moves he was
          able to pull off. Tonight I have my first yoga class and I intend to
          do yoga regularly so I can be flexible.
        </p>
        <h3>I was scared</h3>
        <p>
          I think just the experience of successfully descending an X canyon in
          conjunction with writing this postmortem is pretty theraputic. I know
          the most important things I need to do to improve and have a strategy
          to do these things. If I can execute this strategy, it will definitely
          boost my confidence for future X canyons.
        </p>
        <h2>Images</h2>
        <p>
          Now that <em>that's</em> out of the way let's get to some pictures:
        </p>
        <Image
          caption="On the approach looking down into the canyon"
          image={data._1}
        />
        <Image
          caption="After the first rappel and fully committed"
          image={data._2}
        />
        <Image
          caption="Anthony going down the first down climb"
          image={data._3}
        />
        <Image caption="Anthony thinking hard at the crux" image={data._5} />
        <Image caption="And the full view of the crack" image={data._10} />
        <Image caption="Awaiting my turn at the crux" image={data._11} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-03-03-sandthrax')(
  Blog_2018_03_03_Sandthrax
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-10.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: {
        eq: "images/blog/2018-03-03-sandthrax/2018_03_03_Sandthrax-11.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
